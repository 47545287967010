import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { IoIosArrowForward } from 'react-icons/io';
import { menuItems } from '../menuItems';

interface MobileMenuProps {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  toggleModal: () => void;
}

interface ToggleProps {
  toggleMenu: () => void;
  isMenuOpen: boolean;
}

const mobileMenuVariants = {
  open: {
    scaleY: 1,
    opacity: 1,
    display: 'block',
    transition: {
      scaleY: { duration: 0.3, delay: 0.1 },
      opacity: { duration: 0.2, delay: 0.1 }
    }
  },
  closed: {
    scaleY: 0,
    opacity: 0,
    transition: {
      scaleY: { duration: 0.2 },
      opacity: { duration: 0.2 }
    },
    transitionEnd: { display: 'none' }
  }
};

const topBunVariants = {
  open: { rotate: 45, y: 6, x: 1, backgroundColor: 'rgba(255, 255, 255, 1)' }, // white
  closed: { rotate: 0, y: 0, x: 0, backgroundColor: 'rgba(255, 255, 255, 1)' }, // white
};
const centerBunVariants = {
  open: 'bg-transparent',
  closed: 'bg-white',
};
const bottomBunVariants = {
  open: { rotate: -45, y: -6, x: 1, backgroundColor: 'rgba(255, 255, 255, 1)' }, // white
  closed: { rotate: 0, y: 0, x: 0, backgroundColor: 'rgba(255, 255, 255, 1)' }, // white
};

const menuItemVariants = {
  open: { y: 0, opacity: 1 },
  closed: { y: -20, opacity: 0 }
};

export const MobileMenuButton: React.FC<ToggleProps> = ({ toggleMenu, isMenuOpen }) => (
  <button className="xl:hidden z-50 bg-transparent rounded-none transition-none hover:scale-100 hover:shadow-none" onClick={toggleMenu}>
    <motion.div className="w-8 h-0.5 bg-white mb-1" variants={topBunVariants} animate={isMenuOpen ? 'open' : 'closed'} />
    <motion.div className={"w-8 h-0.5 mb-1 flex " + (isMenuOpen ? centerBunVariants.open : centerBunVariants.closed)} />
    <motion.div className="w-8 h-0.5 bg-white" variants={bottomBunVariants} animate={isMenuOpen ? 'open' : 'closed'} />
  </button>
);

const MobileMenu: React.FC<MobileMenuProps> = ({ isMenuOpen, toggleMenu, toggleModal }) => (
  <motion.nav 
    initial="closed"
    animate={isMenuOpen ? "open" : "closed"}
    variants={mobileMenuVariants}
    className="fixed top-24 md:top-36 left-0 w-full bg-black bg-opacity-70 backdrop-filter backdrop-blur-lg p-4 z-40 origin-top"
  >
    <ul className="flex flex-col items-center space-y-4">
      {menuItems.map((item, index) => (
        <motion.li key={index} variants={menuItemVariants} className="text-center flex items-center space-x-3 py-2">
          <Link to={item.path} onClick={() => toggleMenu()} className="flex items-center space-x-2 hover:text-red-500">
            <span className="text-white text-lg">{item.name}</span>
            <IoIosArrowForward className="text-redz-gold" />
          </Link>
        </motion.li>
      ))}
    </ul>
  </motion.nav>
);

export default MobileMenu;