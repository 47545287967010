import React from 'react';
import { NavLink } from 'react-router-dom';
import { motion } from 'framer-motion';
import { menuItems } from '../menuItems';

const NavLinks: React.FC = () => {

  return (
    <nav className="hidden xl:flex items-center space-x-10 ml-auto text-yellow-500 relative">
      {menuItems.map((item) => (
        <div key={item.name} className="relative">
          <NavLink to={item.path} className="relative">
            {({ isActive }) => (
              <div className="relative flex flex-col items-center">
                <span 
                  className={`relative z-10 transition-colors duration-300 ${isActive ? 'font-bold text-yellow-300' : 'text-white hover:text-yellow-300'}`}
                >
                  {item.name}
                </span>
                {isActive && (
                  <motion.div
                    layoutId="underline"
                    className="absolute bottom-0 w-7 h-0.5 bg-yellow-300 rounded-full"
                    style={{ bottom: '-5px' }} // Adjust for vertical spacing
                    transition={{ duration: 0.3 }}
                  />
                )}
              </div>
            )}
          </NavLink>
        </div>
      ))}
      <div
      />
    </nav>
  );
};

export default NavLinks;