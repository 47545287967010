import React from 'react';

interface ProjectCardProps {
  src: string;
  href: string;
  title: string;
  description: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ src, href, title, description }) => {
  return (
    <div
      className="group relative rounded-lg overflow-hidden transform transition-transform hover:scale-105"
      style={{
        backgroundColor: '#213146',
        boxShadow: '0 8px 12px rgba(0, 0, 0, 0.1)',
        borderRadius: '16px',
      }}
    >
      <iframe
        src={src}
        className="w-full h-[75vh] sm:h-[65vh] md:h-[50vh] border-none"
        title={title}
      ></iframe>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="absolute inset-0"
        aria-label={title}
      ></a>
      <div className="p-4 md:p-8">
        <h3 className="text-2xl sm:text-3xl font-museo font-bold text-yellow-500 group-hover:text-yellow-300 mb-2 sm:mb-4">
          {title}
        </h3>
        <p className="text-base sm:text-lg font-museo text-gray-400 group-hover:text-gray-200">
          {description}
        </p>
      </div>
    </div>
  );
};

export default ProjectCard;