import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#1E3146] text-white py-8">
      <div className="container mx-auto px-4 flex flex-col items-center">
        <p className="text-lg font-museo mb-4">Contact Us</p>
        <a
          href="mailto:Kev@goldenglobemarketing.com"
          className="text-yellow-500 hover:text-yellow-300 transition-colors duration-300 text-lg font-museo"
        >
          Kev@goldenglobemarketing.com
        </a>
        <p className="text-sm font-museo text-gray-400 mt-4">
          © {new Date().getFullYear()} Golden Globe Marketing. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;