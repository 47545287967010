import React from 'react';
import { Link } from 'react-router-dom';
import LogoImage from '../../../assets/images/logo.png'; // Update the logo path as needed

const Logo: React.FC = () => (
  <Link to="/" className="mr-4 sm:mr-6 md:mr-8 flex items-center">
    <img 
      src={LogoImage} 
      alt="Logo" 
      className="w-16 sm:w-20 md:w-24 h-auto" 
    />
  </Link>
);

export default Logo;