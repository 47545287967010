import { FC, ReactNode } from "react";
import Footer from "./Footer/Footer";
import { ScrollRestoration } from "react-router-dom";
import Header from "./Header/index";
import { AnimatePresence } from "framer-motion";

// Toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface RootProps {
  children?: ReactNode;
}

const Root: FC<RootProps> = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen text-black">
      <AnimatePresence>
        <Header key="header" />
        <div key="content" className="flex-grow relative z-0 overflow-x-hidden bg-white">
          {children}
        </div>
        <Footer key="footer" />
        <ToastContainer 
          position="top-right" 
          className='mt-36'
          autoClose={2500} 
          key="toast"
        />
        <ScrollRestoration 
          getKey={(location) => location.pathname} 
          key="scroll" 
        />
      </AnimatePresence>
    </div>
  );
}

export default Root;