import React, { Suspense } from 'react';
import { AnimatePresence } from 'framer-motion';
import Root from './pieces/Root';
import { Navigate, Route, Routes } from 'react-router-dom';
import ErrorBoundary from './pieces/Root/ErrorBoundary';
import Home from './pieces/Home';


const App = () => {
  return (
    <Root>
      <ErrorBoundary>
      {/* Wrap the routes with Suspense and provide a fallback for loading state */}
        <Suspense fallback={<div>Loading...</div>}>
          <AnimatePresence mode="wait">
            <Routes>
              <Route path="/" element={<Home />}/>

              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </AnimatePresence>
        </Suspense>
        </ErrorBoundary>
    </Root>
  );
};

export default App;