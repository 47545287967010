import React from 'react';

const HeroSection: React.FC = () => {
  return (
    <div className="text-center px-4 sm:px-6 md:px-8">
      <h1
        className="text-4xl sm:text-5xl md:text-6xl font-poster font-bold tracking-wide mb-6 sm:mb-8 text-transparent bg-clip-text"
        style={{
          backgroundImage: 'linear-gradient(90deg, #FFC857, #FFD700)',
          letterSpacing: '0.1em',
        }}
      >
        Golden Globe Marketing
      </h1>
      <p className="text-lg sm:text-xl md:text-2xl font-museo mb-8 sm:mb-10 md:mb-12 text-gray-300 leading-relaxed max-w-xl sm:max-w-2xl md:max-w-3xl mx-auto">
        We are busy crafting unique and powerful digital experiences. <br className="leading-none" />
        <br className="leading-none" /> Meanwhile, feel free to explore some of our recent work:
      </p>
    </div>
  );
};

export default HeroSection;