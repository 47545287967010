import React from 'react';
import HeroSection from './HeroSection';
import ProjectsGrid from './ProjectsGrid';

const HomePage: React.FC = () => {
  return (
    <div
      className="min-h-screen flex flex-col items-center justify-center pt-[20vh]"
      style={{ backgroundColor: '#1E3146' }}
    >
      <HeroSection />
      <ProjectsGrid />
    </div>
  );
};

export default HomePage;