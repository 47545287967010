import React from 'react';
import ProjectCard from './ProjectCard';

const ProjectsGrid: React.FC = () => {
  const projects = [
    {
      src: 'https://holdrens.com',
      href: 'https://holdrens.com',
      title: "Holdren's",
      description: 'A classic steakhouse experience in Santa Barbara.',
    },
    {
      src: 'https://silverstream.cafe',
      href: 'https://silverstream.cafe',
      title: 'Silverstream Cafe',
      description: 'A cozy and quaint café for all your cravings.',
    },
    // {
    //   src: 'https://sbchime.com',
    //   href: 'https://sbchime.com',
    //   title: 'SB Chime',
    //   description: 'A blog with hundreds of insights and life lessons for young men.',
    // },
    {
      src: 'https://bayandbasinins.com',
      href: 'https://bayandbasinins.com',
      title: 'Bay and Basin Insurance',
      description: 'Comprehensive insurance solutions tailored to your needs.',
    },
    {
      src: 'https://viamaestraenoteca.com',
      href: 'https://viamaestraenoteca.com',
      title: 'Via Maestra Enoteca',
      description: 'A community wine bar and shop offering a curated selection of fine wines.',
    },
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 sm:gap-8 lg:gap-12 max-w-6xl md:max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {projects.map((project) => (
        <ProjectCard
          key={project.title}
          src={project.src}
          href={project.href}
          title={project.title}
          description={project.description}
        />
      ))}
    </div>
  );
};

export default ProjectsGrid;